import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModalWrapper from "./Helpers/ModalWrapper";
import PropTypes from "prop-types";
import CustomSelect from "../hub/HelperComponents/CustomSelect";
import SimplePurpleCalendar from "../Menus/shared/SimplePurpleCalendar";
import {
  daysOfTheWeek,
  getDateText,
  getDateValues,
  getScheduleFromRRuleText,
  getScheduleFromSendOn,
  getScheduleMessageSummary,
  getScheduleRRule,
  getSendOnFromStartDateTimeAndPeriod,
  RepeatType,
  resetTimeOnDate,
} from "../../utils/recurringMessagesHelpers";
import { TextField } from "@material-ui/core";

const ScheduleMessageModal = (props) => {
  const { show, closeModal, recurringRule, onSubmit, sendOn } = props;

  const initialState = useMemo(() => {
    if (recurringRule) {
      return getScheduleFromRRuleText(recurringRule);
    } else {
      return getScheduleFromSendOn(sendOn);
    }
  }, [recurringRule, sendOn]);

  const [schedule, setSchedule] = useState(initialState);

  const [showStartDateCalendarPopover, setShowStartDateCalendarPopover] =
    useState(false);

  const [showEndDateCalendarPopover, setShowEndDateCalendarPopover] =
    useState(false);

  useEffect(() => {
    setSchedule(initialState);
  }, [initialState]);

  const customRepeatMessageOptions = useMemo(() => {
    if (!schedule) return repeatMessageOptions;
    return repeatMessageOptions.map((option) => {
      if (option.value === RepeatType.Weekly) {
        const { dayOfTheWeek } = getDateValues(schedule.startDate);
        option.name = `Weekly On ${daysOfTheWeek[dayOfTheWeek]}`;
      }
      if (option.value === RepeatType.MonthlyOnFirstSelectedWeekDay) {
        const { dayOfTheWeek } = getDateValues(schedule.startDate);
        option.name = `Monthly on First ${daysOfTheWeek[dayOfTheWeek]}s`;
      }
      return option;
    });
  }, [schedule]);

  const callOtherFunctionsBasedOnField = (field) => {
    if (field === "startDate") {
      setShowStartDateCalendarPopover(false);
    }
    if (field === "endDate") {
      setShowEndDateCalendarPopover(false);
    }
  };

  const getTimeOnFieldChange = (prev, field, value) => {
    value = parseInt(value);
    const maxValue = field === "hour" ? 12 : 59;
    const minValue = field === "hour" ? 1 : 0;
    if (value > maxValue) {
      value = maxValue;
    }
    if (value < minValue) {
      value = minValue;
    }
    if (value < 10 && field === "minute") {
      value = `0${value}`;
    }
    if (field === "hour") {
      value = value + ":" + prev.time.split(":")[1];
    } else {
      value = prev.time.split(":")[0] + ":" + value;
    }
    return value;
  };

  const getFieldsToUpdate = useCallback((prev, field, value) => {
    const fieldsToUpdate = {
      [field]: value,
    };
    if (field === "startDate") {
      fieldsToUpdate.startDate = resetTimeOnDate(value);
    } else if (field === "endDate" && value) {
      fieldsToUpdate.endDate = resetTimeOnDate(value);
    } else if (
      (field === "endDate" &&
        getDateText(prev.endDate) === getDateText(value)) ||
      (value === RepeatType.DoesNotRepeat && prev.endDate)
    ) {
      fieldsToUpdate.endDate = null;
    } else if (field === "hour") {
      delete fieldsToUpdate.hour;
      fieldsToUpdate.time = getTimeOnFieldChange(prev, field, value);
    } else if (field === "minute") {
      delete fieldsToUpdate.minute;
      fieldsToUpdate.time = getTimeOnFieldChange(prev, field, value);
    }
    return fieldsToUpdate;
  }, []);

  const onValueChange = useCallback(
    () => (field) => (e) => {
      const value = e?.target?.value || e;
      callOtherFunctionsBasedOnField(field);
      setSchedule((prev) => ({
        ...prev,
        ...getFieldsToUpdate(prev, field, value),
      }));
    },
    [getFieldsToUpdate]
  );

  const handleConfirm = useCallback(() => {
    let rruleText = null;
    let currentSendOn = getSendOnFromStartDateTimeAndPeriod(
      schedule.startDate,
      schedule.time,
      schedule.period
    );
    if (schedule.repeatType !== RepeatType.DoesNotRepeat) {
      rruleText = getScheduleRRule(schedule);
    }
    onSubmit({ currentSendOn, rruleText, schedule });
    closeModal();
  }, [onSubmit, schedule, closeModal]);

  return (
    <ModalWrapper
      id="schedule-message"
      className="schedule-message-modal"
      show={show}
      closeModal={closeModal}
    >
      <div className="schedule-message-container">
        <div className="schedule-message-container-header">
          Schedule Message
        </div>
        <div className="schedule-message-container-content">
          <div className="schedule-message-container-content-label">
            Specify Date
          </div>
          <CustomSelect
            className="mb-4"
            fullWidth
            underline
            value={schedule.startDate}
            options={[
              {
                name: getDateText(schedule.startDate),
                value: schedule.startDate,
              },
            ]}
            customPopoverOpen={showStartDateCalendarPopover}
            setCustomPopoverOpen={setShowStartDateCalendarPopover}
            customPopoverComponent={
              <SimplePurpleCalendar
                minDate={new Date()}
                value={schedule.startDate}
                onChange={onValueChange()("startDate")}
              />
            }
          />
          <div className="schedule-message-container-content-label">
            Specify Time
          </div>
          <div className="d-flex mb-4 justify-content-md-between">
            <div className="w-100">
              <CustomSelect
                disabled
                fullWidth
                underline
                value={timeOptions[0].value}
                options={timeOptions}
              />
            </div>
            <div className="w-100 ml-3 d-flex" id="timeComponent">
              <TextField
                id="time"
                type="number"
                className="schedule-message-container-content-time-picker"
                onChange={onValueChange()("hour")}
                value={schedule.time.split(":")[0]}
                inputProps={{
                  className: "text-center",
                }}
              />
              <div className="schedule-message-container-colon mt-1">:</div>
              <TextField
                id="time"
                type="number"
                className="schedule-message-container-content-time-picker"
                inputProps={{
                  className: "text-center",
                }}
                onChange={onValueChange()("minute")}
                value={schedule.time.split(":")[1]}
              />
            </div>
            <div className="ml-3">
              <CustomSelect
                fullWidth
                underline
                value={schedule.period}
                options={periodOptions}
                onChange={onValueChange()("period")}
              />
            </div>
          </div>

          <div className="schedule-message-container-content-label">
            Repeat Message?
          </div>

          <CustomSelect
            className="mb-4"
            fullWidth
            underline
            value={schedule?.repeatType}
            options={customRepeatMessageOptions}
            onChange={onValueChange()("repeatType")}
          />
          {schedule.repeatType !== RepeatType.DoesNotRepeat && (
            <>
              <div className="schedule-message-container-content-label">
                Specify End Date (Optional)
              </div>
              <CustomSelect
                className="mb-4"
                fullWidth
                underline
                value={schedule.endDate}
                options={[
                  {
                    name: "None",
                    value: null,
                  },
                  {
                    name: getDateText(schedule.endDate),
                    value: schedule.endDate,
                  },
                ]}
                customPopoverOpen={showEndDateCalendarPopover}
                setCustomPopoverOpen={setShowEndDateCalendarPopover}
                customPopoverComponent={
                  <SimplePurpleCalendar
                    minDate={schedule.startDate || new Date()}
                    value={schedule.endDate}
                    onChange={onValueChange()("endDate")}
                  />
                }
              />
            </>
          )}
        </div>

        <div className="schedule-message-container-summary">
          <div className="schedule-message-container-summary-title">
            Summary
          </div>
          <div className="schedule-message-container-summary-description">
            {getScheduleMessageSummary(schedule)}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleConfirm}>
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

const repeatMessageOptions = [
  {
    name: "Does not Repeat",
    value: RepeatType.DoesNotRepeat,
  },
  {
    name: "Daily",
    value: RepeatType.Daily,
  },
  {
    name: "Weekly",
    value: RepeatType.Weekly,
  },
  {
    name: "Weekdays (Monday to Friday)",
    value: RepeatType.WeekDays,
  },
  {
    name: "Bi-Weekly",
    value: RepeatType.BiWeekly,
  },
  {
    name: "Monthly",
    value: RepeatType.MonthlyOnFirstSelectedWeekDay,
  },
  {
    name: "Annually",
    value: RepeatType.Yearly,
  },
];

const timeOptions = [
  {
    name: "At",
    value: null,
  },
];

const periodOptions = [
  {
    name: "AM",
    value: "AM",
  },
  {
    name: "PM",
    value: "PM",
  },
];

ScheduleMessageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  recurringRule: PropTypes.string,
  sendOn: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
ScheduleMessageModal.defaultProps = {
  show: true,
  recurringRule: null,
  sendOn: null,
};

export default ScheduleMessageModal;
