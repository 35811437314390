import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { Popover } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import LayoutDefault from "../LayoutDefault";
import SendNewMessageHeader from "./SendNewMessageHeader/SendNewMessageHeader";
import SendNewMessageBody from "./SendNewMessageBody/SendNewMessageBody";
import { ReactComponent as SendGrey } from "../../assets/img/icons-new/general/send/send-light-grey.svg";
import { ReactComponent as SendSvg } from "../../assets/img/icons-new/general/send/send-white.svg";
import RecipientsSideForm from "./SendNewMessageBody/RecipientsSideForm";
import CreditEstimate from "../hub/HubThreadMessageForm/AboveTextArea/CreditEstimate";
import InvalidTimeModal from "../modals/InvalidTimeModal";
import { ReactComponent as ScheduledWhiteSvg } from "../../assets/img/icons-new/message-tab/scheduled/scheduled-white.svg";
import { ReactComponent as ScheduledLightGreySvg } from "../../assets/img/icons-new/message-tab/scheduled/scheduled-light-gray.svg";
import AlertNotifier from "../hub/HubThreadMessageForm/AlertNotifier/AlertNotifier";
import {
  getCreditsCostAndStats,
  getNonGsmCharactersCount,
  formatDateTime5,
  getAppTime,
  getFirstShortCodeNumber,
  getVerifiedTollFreeNumber,
  getSignatureToInsert,
  getContactDataReduxForMapToProps,
  isEmptyObject,
  getContactInfo,
  hasNumberAssigned,
} from "../../helpers";
import {
  mapTemplateSignature,
  getKeyForSignatureMergeFields,
  isNotAnEmptyArray,
} from "../../utils/settingsHelpers";
import { ALL_MERGE_FIELDS } from "../../utils/enums/threadEnums";
import { GROUP, CONTACT } from "../../utils/constants";
import { getSmsThrottleInfoSelector } from "../../selectors/helpersSelectors";

import useGroupsStore from "../../utils/hooks/ReduxHooks/groupsStore";
import { useNumbersStore } from "../../utils/hooks/store/useNumbersStore";
import useMessagesStore from "../../utils/hooks/ReduxHooks/messagesStore";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import useSettingsStore from "../../utils/hooks/ReduxHooks/settingsStore";
import usePreviousUpdated from "../../utils/hooks/usePreviousUpdated";
import MessageFormModalManager from "../hub/HubThreadMessageForm/MessageFormModalManager";
import { useAccountIssueInfo } from "../../utils/hooks/useAccountIssueInfo";
import { isAccountIssueModalIssueKey } from "../../utils/accountHelpers";
import { getRecipientContactDetails } from "../../utils/sendMessageHelpers";
import { useThreadsStore } from "../../utils/hooks/store/useThreadsStore";
import { FormPrompt } from "../hub/HelperComponents/FormPrompt";
import { useIsAdminOrOwner } from "../../utils/hooks/useIsAdminOrOwner";
import { useComponentWillUnmount } from "../../utils/hooks/useComponentWillUnmount";
import { checkUrlContentType } from "../../utils/filestackHelpers";
import { mapImageUrlsToObject } from "../../utils/messagesHelpers";
import {
  getScheduleFromRRuleText,
  getScheduleFromSendOn,
} from "../../utils/recurringMessagesHelpers";
import { useComponentWillMount } from "../../utils/hooks/useComponentWillMount";
import MessageDeliveryAlertNotifier from "../hub/HubThreadMessageForm/AlertNotifier/MessageDeliveryAlertNotifier";

const SendNewMessage = () => {
  // Redux store
  const {
    groups,
    clearAddGroup,
    clearPatchGroupsStatus,
    clearGroupIsNewGroup,
  } = useGroupsStore();
  const {
    numbers,
    setSignatureToInsert,
    setSenderNumber,
    fetchSignatureMergeFields,
    clearFetchSignatureMergeFieldStatus,
  } = useNumbersStore();
  const {
    messages,
    toggleSignatureRemoved,
    sendMessage,
    clearSendMessage,
    clearFilteredRecipients,
    clearRecipientGroupsForMessage,
    setExistingGroupId,
    clearManuallyAddedContactIds,
    toggleSendMessageWizard,
  } = useMessagesStore();
  const { users } = useUsersStore();
  const {
    threads,
    setScheduledVisible,
    clearAddVcardStatus,
    markInitialLoadSuccess,
    setFilter,
    setMessagesTab,
  } = useThreadsStore();
  const { addDataForModal } = useMainStore();
  const { companies, fetchUsers } = useCompaniesStore();
  const { settings } = useSettingsStore();
  const { main } = useMainStore();
  const { dataForModal } = main;
  const { data, addId } = groups;
  const {
    signatures,
    signatureToInsert,
    signatureMergeFields,
    senderNumber,
    useSecondaryNumber,
    signatureMergeFieldsStatus,
  } = numbers;
  const { loggedUser } = users;
  const { scheduledVisible, addVcardsStatus, vCards: vCardsInRedux } = threads;
  const { currentCompany, usersStatus } = companies;
  const {
    isSignatureRemoved,
    sendStatus,
    sendErrorStatus,
    filteredRecipients,
    sendError,
    existingGroupId,
  } = messages;

  // Other hooks
  const shortCodeNumber = getFirstShortCodeNumber(numbers.numbers);
  const verifiedTollFreeNumber = getVerifiedTollFreeNumber(numbers.numbers);
  const prevSenderNumber = usePreviousUpdated(senderNumber);
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  // Route
  const location = useLocation();
  const history = useHistory();

  // Local component state
  const [sidebar, setSidebar] = useState(false);
  const [messageValue, setMessageValue] = useState("");

  const [sendOn, setSendOn] = useState(null);
  const [recurringRule, setRecurringRule] = useState(null);
  const [invalidTimeModal, setInvalidTimeModal] = useState(false);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [vCards, setVcards] = useState([]);
  const [isVideoProcessing, setIsVideoProcessing] = useState(false);
  const [isImagesProcessing, setIsImagesProcessing] = useState(false);
  const [imagesErrorText, setImagesErrorText] = useState("");
  const [videoErrorText, setVideoErrorText] = useState("");
  const [videoLengthExceedData, setVideoLengthExceedData] = useState({});
  const [uploadedVideo, setUploadedVideo] = useState({});
  const [showSendError, setShowSendError] = useState(false);
  const [messageSignature, setMessageSignature] = useState("");
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [sendMoreAnchor, setSendMoreAnchor] = useState(null);
  const [confirmedRestrictedPhrase, setConfirmedRestrictedPhrase] =
    useState(false);
  const [confirmedLinkImageWarning, setConfirmedLinkImageWarning] =
    useState(false);
  const [alreadyScheduledConfirmed, setAlreadyScheduledConfirmed] =
    useState(false);
  const [warningText, setWarningText] = useState("");
  const [throttleWarning, setThrottleWarning] = useState({
    show: false,
    subscribersCount: 1,
  });
  const [showSharedNumberAlert, setShowSharedNumberAlert] = useState(false);
  const [showNoShorteningLinksWarning, setShowNoShorteningLinksWarning] =
    useState(false);
  const [linksCount, setLinksCount] = useState(0);
  const [mainGroupId, setMainGroupId] = useState(0);

  const noShorteningLinksWarningTimer = useRef();
  const boxRef = useRef(null);

  const [currentModal, setCurrentModal] = useState("");
  const [editScheduledMessage, setEditScheduledMessage] = useState(null);
  const [schedule, setScheduleData] = useState(null);

  const [isDirty, setIsDirty] = useState(false);

  const [membersAdvFilters, setMembersAdvFilters] = useState([]);

  const accountIssueInfo = useAccountIssueInfo();

  // Memoized variables
  const withAssignedNumber = useMemo(
    () => hasNumberAssigned(numbers?.numbers),
    [numbers]
  );

  const threadType = useMemo(() => {
    if (mainGroupId > 0) {
      return GROUP;
    } else if (
      filteredRecipients?.length === 1 &&
      typeof filteredRecipients[0]?.group_name === "undefined"
    ) {
      return CONTACT;
    }
    return GROUP;
  }, [filteredRecipients, mainGroupId]);

  const interlocutorId = useMemo(() => {
    if (mainGroupId > 0) {
      return mainGroupId;
    } else if (
      filteredRecipients?.length === 1 &&
      typeof filteredRecipients[0]?.group_name === "undefined"
    ) {
      return filteredRecipients[0].id;
    }
    return null;
  }, [filteredRecipients, mainGroupId]);

  const groupData = useMemo(() => {
    if (threadType === GROUP && !isEmptyObject(data)) {
      return data[interlocutorId] || null;
    }
    return null;
  }, [data, interlocutorId, threadType]);

  const contactData = useSelector((store) =>
    getContactDataReduxForMapToProps(store, threadType, interlocutorId)
  );

  const { isBlocked, isUnsubscribed, unsubedByUserId } =
    getRecipientContactDetails(contactData, threadType, groupData);

  const { contactFullNumber, contactFullName } = useMemo(
    () => getContactInfo(contactData),
    [contactData]
  );

  const subscribersCount = useMemo(() => {
    if (
      data[interlocutorId] &&
      data[interlocutorId].members_msg_receivers > 0
    ) {
      return data[interlocutorId].members_msg_receivers;
    }
    return 0;
  }, [data, interlocutorId]);

  const smsThrottles = useMemo(() => {
    return settings.settings ? settings.settings.smsThrottles : [];
  }, [settings]);

  const isSharesNumber = useMemo(() => {
    return loggedUser && loggedUser.is_shares_number > 0;
  }, [loggedUser]);

  const isMainOwner = useMemo(() => {
    return currentCompany && currentCompany.is_main_owner > 0;
  }, [currentCompany]);

  const checkMediaUrlTypes = useCallback((data) => {
    try {
      data.forEach(async (el) => {
        const res = await checkUrlContentType(el);
        if (res === "vcard") {
          setVcards((prev) => [...prev, el]);
        }
        if (res === "video") {
          setVideos((prev) => [...prev, { file: { url: el } }]);
        }
        if (res === "image") {
          setImages(mapImageUrlsToObject(data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getInitialSignatureDetails = useCallback(
    (editRecurringRule, editSendOn) => {
      if (editRecurringRule) {
        return getScheduleFromRRuleText(editRecurringRule);
      } else {
        return getScheduleFromSendOn(editSendOn);
      }
    },
    []
  );

  useComponentWillMount(() => {
    // Clear add group id and is new group status on component mount
    clearGroupIsNewGroup();
    clearAddGroup();
  });

  useEffect(() => {
    // Check if the user is trying to edit a scheduled message
    if (
      location?.state?.dataForEditScheduleMsg &&
      !isEmptyObject(location?.state?.dataForEditScheduleMsg)
    ) {
      const scheduleMessageForEdit =
        location?.state?.dataForEditScheduleMsg?.schedule_message;
      if (scheduleMessageForEdit?.signature_id) {
        const dataToInsert = getSignatureToInsert({
          signatures,
          chosenNumber:
            location?.state?.dataForEditScheduleMsg?.from_number ||
            senderNumber,
          loggedUser,
          users: companies?.users,
          numbers: numbers?.numbers,
          currentCompany,
          threadType,
        });
        setSignatureToInsert(dataToInsert);
      }

      if (scheduleMessageForEdit?.msg_type === 1) {
        setSendOn(scheduleMessageForEdit.send_on_utc);
        setRecurringRule(scheduleMessageForEdit.recurring_rule);
        setMessageValue(scheduleMessageForEdit.message);
      } else if (scheduleMessageForEdit?.msg_type === 2) {
        setSendOn(scheduleMessageForEdit.send_on_utc);
        setRecurringRule(scheduleMessageForEdit.recurring_rule);
        setMessageValue(scheduleMessageForEdit.mms_text);
        if (scheduleMessageForEdit.message) {
          const urlsArray = scheduleMessageForEdit?.message?.split(",");
          checkMediaUrlTypes(urlsArray);
        }
      }
      const initialScheduleDetails = getInitialSignatureDetails(
        scheduleMessageForEdit.recurring_rule,
        scheduleMessageForEdit.send_on_utc
      );
      setSenderNumber(location?.state?.dataForEditScheduleMsg?.from_number);
      setEditScheduledMessage(location?.state?.dataForEditScheduleMsg);
      setScheduleData(initialScheduleDetails);
      setCurrentModal("schedule-message");
      history.replace({
        pathname: "/send-new-message",
        state: {},
      });
    }
  }, [
    checkMediaUrlTypes,
    currentCompany,
    location,
    loggedUser,
    numbers,
    senderNumber,
    setSenderNumber,
    setSignatureToInsert,
    signatures,
    threadType,
    users,
    history,
    companies,
    getInitialSignatureDetails,
  ]);

  useEffect(() => {
    // Fetch the signature merge fields
    if (loggedUser?.id && senderNumber?.number) {
      fetchSignatureMergeFields(
        currentCompany.id,
        loggedUser.id,
        senderNumber.number
      );
    }
  }, [currentCompany, fetchSignatureMergeFields, loggedUser, senderNumber]);

  useEffect(() => {
    // Fetch updated users list
    if (isAdminOrOwner) fetchUsers({ companyId: currentCompany.id });
  }, [currentCompany, fetchUsers, isAdminOrOwner]);

  useEffect(() => {
    // set signature to insert when user changes the sender number
    if (
      signatureMergeFieldsStatus === "success" &&
      senderNumber &&
      signatures.length > 0 &&
      usersStatus === "success" &&
      numbers?.numbers &&
      Array.isArray(numbers?.numbers)
    ) {
      const dataToInsert = getSignatureToInsert({
        signatures,
        chosenNumber: senderNumber.number,
        loggedUser,
        users: companies?.users,
        numbers: numbers?.numbers,
        currentCompany,
        threadType,
      });
      setSignatureToInsert(dataToInsert);
      clearFetchSignatureMergeFieldStatus();
    }
  }, [
    currentCompany,
    loggedUser,
    numbers,
    senderNumber,
    setSignatureToInsert,
    signatureMergeFieldsStatus,
    signatures,
    threadType,
    companies,
    usersStatus,
    clearFetchSignatureMergeFieldStatus,
  ]);

  useComponentWillUnmount(() => {
    // Clear all the states when the component unmounts
    clearFilteredRecipients();
    clearGroupIsNewGroup();
    clearAddGroup();
    clearRecipientGroupsForMessage();
    setExistingGroupId(null);
    clearPatchGroupsStatus();
    clearManuallyAddedContactIds();
  }, []);

  useEffect(() => {
    // Handle show browser warning when user try to leave the page with unsaved changes
    if (!sendStatus) {
      if (
        messageValue ||
        isNotAnEmptyArray(images) ||
        isNotAnEmptyArray(videos) ||
        isNotAnEmptyArray(vCards) ||
        isNotAnEmptyArray(filteredRecipients)
      ) {
        if (!isDirty) {
          setIsDirty(true);
        }
      } else {
        if (isDirty) {
          setIsDirty(false);
        }
      }
    }
  }, [
    images,
    isDirty,
    messageValue,
    sendStatus,
    vCards,
    videos,
    filteredRecipients,
  ]);

  const clearWarnings = useCallback(() => {
    setVideoErrorText("");
    setImagesErrorText("");
    setThrottleWarning({
      show: false,
      subscribersCount,
    });
    setShowSharedNumberAlert(false);
    setShowNoShorteningLinksWarning(false);
    setShowSendError(false);
  }, [subscribersCount]);

  const clearScheduledDate = useCallback(() => {
    setRecurringRule(null);
    setSendOn(null);
  }, []);

  const clearSendingData = useCallback(() => {
    setMessageValue("");
    clearScheduledDate();
    setImages([]);
    setVideos([]);
    clearAddGroup();
    clearGroupIsNewGroup();
  }, [clearScheduledDate, clearAddGroup, clearGroupIsNewGroup]);

  const handleToggleRecipients = useCallback(() => {
    setSidebar(!sidebar);
  }, [sidebar, setSidebar]);

  const numberSignature = useMemo(() => {
    const signature = mapTemplateSignature(
      signatureToInsert.signature,
      signatureToInsert?.signatureUserId &&
        signatureToInsert?.signatureUserNumber
        ? signatureMergeFields[
            getKeyForSignatureMergeFields(
              signatureToInsert.signatureUserId,
              signatureToInsert.signatureUserNumber
            )
          ]
        : null
    );
    return signature;
  }, [signatureMergeFields, signatureToInsert]);

  const getCreditsCostAndStatsInner = useCallback(
    (text, unicodeEncoding) => {
      return getCreditsCostAndStats(
        `${text}${messageSignature ? ` ${messageSignature}` : ""}`,
        unicodeEncoding,
        senderNumber,
        subscribersCount,
        [...images, ...videos, ...vCards]
      );
    },
    [images, videos, senderNumber, messageSignature, vCards, subscribersCount]
  );

  const unicodeCharacters = getNonGsmCharactersCount(
    `${messageValue}${messageSignature ? " " + messageSignature : ""}`
  );

  const {
    calculatedCredits,
    characters = 0,
    smsCount,
    mmsCount,
  } = getCreditsCostAndStatsInner(messageValue, unicodeCharacters > 0);

  const getDataToSend = useCallback(() => {
    let imagesUrls = [];
    let videosUrls = [];
    for (let i = 0; i < images.length; i++) {
      imagesUrls.push(images[i].url);
    }
    for (let i = 0; i < videos.length; i++) {
      videosUrls.push(videos[i].file?.url);
    }
    let textToSend = messageValue;

    // Convert Merge templates using Regex
    ALL_MERGE_FIELDS.forEach((field) => {
      const regex = new RegExp(`\\[${field.text}\\]`, "gi");
      textToSend = textToSend.replace(regex, `[${field.text}]`);
    });

    if (editScheduledMessage?.is_email === 1) {
      textToSend =
        editScheduledMessage.msg_type === 2
          ? editScheduledMessage.mms_text
          : editScheduledMessage.message;
    }

    return {
      imagesUrls: [...imagesUrls, ...videosUrls, ...vCards],
      textToSend,
    };
  }, [images, messageValue, videos, vCards, editScheduledMessage]);

  const handleSend = useCallback(
    (
      message,
      images,
      sendOn,
      recurringRule,
      restrictedConfirmed = 0,
      differentSenderConfirmed = false,
      useSecondary = false,
      linkImageWarningConfirmed = 0,
      alreadyScheduledConfirmed = false
    ) => {
      let textMessage = message;
      let signatureId = signatureToInsert?.signatureId;
      let receiverType = GROUP;
      let receiverId = mainGroupId;
      let editScheduleMessageId = null;

      if (editScheduledMessage) {
        editScheduleMessageId = editScheduledMessage.id;
      }

      if (numberSignature !== messageSignature) {
        textMessage = `${message} ${messageSignature}`;
        signatureId = null;
      }

      if (isSignatureRemoved) {
        signatureId = null;
      }

      if (filteredRecipients.length === 1) {
        receiverType =
          typeof filteredRecipients[0]?.group_name === "undefined"
            ? CONTACT
            : GROUP;
        receiverId = filteredRecipients[0].id;
      }

      sendMessage(
        currentCompany.id,
        receiverType,
        receiverId,
        senderNumber?.number,
        sendOn,
        false /*facebook*/,
        textMessage,
        images,
        restrictedConfirmed,
        editScheduleMessageId,
        useSecondary,
        signatureId,
        signatureToInsert?.signatureUserId,
        signatureToInsert?.signatureUserNumber,
        linkImageWarningConfirmed,
        alreadyScheduledConfirmed,
        recurringRule
      );

      if (!scheduledVisible) {
        setScheduledVisible(true);
      }
    },
    [
      currentCompany,
      mainGroupId,
      scheduledVisible,
      sendMessage,
      senderNumber,
      setScheduledVisible,
      signatureToInsert,
      messageSignature,
      numberSignature,
      isSignatureRemoved,
      filteredRecipients,
      editScheduledMessage,
    ]
  );

  const isMessageEmpty = useMemo(() => {
    return (
      messageValue === "" &&
      images?.length === 0 &&
      videos?.length === 0 &&
      vCards?.length === 0 &&
      numberSignature === messageSignature
    );
  }, [messageValue, images, videos, vCards, numberSignature, messageSignature]);

  const handleSendMessage = useCallback(() => {
    if (
      !isMessageEmpty &&
      (filteredRecipients?.length !== 0 || mainGroupId > 0)
    ) {
      clearWarnings();

      const appTimeDate = new Date(
        formatDateTime5(getAppTime(), loggedUser.timezone_offset)
      );

      // Delete the timezone so we can compare with user timezone
      if (sendOn && new Date(sendOn.substr(0, 19)) < appTimeDate) {
        setInvalidTimeModal(true);
        return;
      }

      if (!senderNumber) return;

      const { textToSend, imagesUrls } = getDataToSend();
      setIsDirty(false);

      handleSend(
        textToSend,
        imagesUrls,
        sendOn,
        recurringRule,
        confirmedRestrictedPhrase,
        false,
        useSecondaryNumber,
        confirmedLinkImageWarning,
        alreadyScheduledConfirmed
      );
    }
  }, [
    alreadyScheduledConfirmed,
    clearWarnings,
    confirmedLinkImageWarning,
    confirmedRestrictedPhrase,
    getDataToSend,
    handleSend,
    isMessageEmpty,
    loggedUser,
    filteredRecipients,
    recurringRule,
    sendOn,
    senderNumber,
    useSecondaryNumber,
    mainGroupId,
  ]);

  const handleCloseInvalidModal = useCallback(() => {
    setInvalidTimeModal(false);
  }, [setInvalidTimeModal]);

  const closeSendMorePopOver = useCallback(() => {
    setSendMoreAnchor(null);
  }, []);

  const handleRemoveSchedule = useCallback(() => {
    setRecurringRule(null);
    setSendOn(null);
    setEditScheduledMessage(null);
    setScheduleData(null);
    closeSendMorePopOver();
  }, [setScheduleData, setSendOn, setRecurringRule, closeSendMorePopOver]);

  const sideContent = useMemo(() => {
    return (
      <RecipientsSideForm
        sidebar={sidebar}
        handleToggleRecipients={handleToggleRecipients}
        recipientsCount={recipientsCount}
        setRecipientsCount={setRecipientsCount}
        mainGroupId={mainGroupId}
        setMainGroupId={setMainGroupId}
        threadType={threadType}
        groupData={groupData}
        advFilters={membersAdvFilters}
        setAdvFilters={setMembersAdvFilters}
      />
    );
  }, [
    sidebar,
    handleToggleRecipients,
    recipientsCount,
    mainGroupId,
    threadType,
    groupData,
    membersAdvFilters,
  ]);

  const sendButtonToolTipTitle = useMemo(() => {
    if (!isMessageEmpty && filteredRecipients?.length === 0)
      return "Add recipients";
    else if (isMessageEmpty && filteredRecipients?.length !== 0)
      return "Add message text";
    return "Add recipients and message text";
  }, [filteredRecipients, isMessageEmpty]);

  const sendButtonIcon = useMemo(() => {
    if (sendStatus === "loading") {
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    }

    if (sendOn && schedule) {
      return <ScheduledWhiteSvg height={18} width={18} />;
    }

    return <SendSvg height={15} width={15} className="d-none d-sm-block" />;
  }, [schedule, sendOn, sendStatus]);

  const openSendMorePopOver = useCallback(
    (e) => {
      if (sendStatus === "loading") return null;
      setSendMoreAnchor(e.currentTarget);
    },
    [sendStatus]
  );

  const handleSubmitScheduleMessage = useCallback(
    ({ currentSendOn, rruleText, schedule }) => {
      // These two states will later be used for handling message send
      setRecurringRule(rruleText);
      setSendOn(currentSendOn);
      setScheduleData(schedule);
    },
    [setSendOn, setRecurringRule]
  );

  const handleEditSchedule = useCallback(() => {
    setCurrentModal("schedule-message");
  }, []);

  const handleSendRestricted = useCallback(() => {
    setConfirmedRestrictedPhrase(true);
    setCurrentModal("");
    setIsDirty(false);

    const { textToSend, imagesUrls } = getDataToSend();

    handleSend(
      textToSend,
      imagesUrls,
      sendOn,
      recurringRule,
      true,
      false,
      useSecondaryNumber,
      confirmedLinkImageWarning,
      alreadyScheduledConfirmed
    );
  }, [
    alreadyScheduledConfirmed,
    confirmedLinkImageWarning,
    getDataToSend,
    handleSend,
    recurringRule,
    sendOn,
    useSecondaryNumber,
  ]);

  const onFormClick = useCallback(() => {
    let onClick = null;

    if (
      accountIssueInfo &&
      isAccountIssueModalIssueKey(accountIssueInfo.issueKey)
    ) {
      onClick = setCurrentModal("account-issue");
    } else if (isUnsubscribed || isBlocked) {
      onClick = setCurrentModal("status-info");
    }
    return onClick;
  }, [accountIssueInfo, isBlocked, isUnsubscribed]);

  const handleSwitchToShortcode = useCallback(
    (isTollFreeNumber) => {
      const senderNumber = isTollFreeNumber
        ? verifiedTollFreeNumber
        : shortCodeNumber;

      const dataToInsert = getSignatureToInsert({
        signatures,
        chosenNumber: senderNumber,
        loggedUser,
        users: companies.users,
        numbers: numbers?.numbers,
        currentCompany,
        threadType,
      });

      setSignatureToInsert(dataToInsert);
      setSenderNumber(senderNumber);
      setCurrentModal("");
    },
    [
      companies,
      currentCompany,
      loggedUser,
      numbers,
      setSenderNumber,
      setSignatureToInsert,
      shortCodeNumber,
      signatures,
      threadType,
      verifiedTollFreeNumber,
    ]
  );

  const handleSendLinkImageWarning = useCallback(() => {
    setConfirmedLinkImageWarning(true);
    setCurrentModal("");
    setIsDirty(false);

    const { textToSend, imagesUrls } = getDataToSend();

    handleSend(
      textToSend,
      imagesUrls,
      sendOn,
      recurringRule,
      confirmedRestrictedPhrase,
      false,
      useSecondaryNumber,
      true,
      alreadyScheduledConfirmed
    );
  }, [
    getDataToSend,
    handleSend,
    sendOn,
    recurringRule,
    confirmedRestrictedPhrase,
    useSecondaryNumber,
    alreadyScheduledConfirmed,
  ]);

  const handleSendAlreadyScheduled = useCallback(() => {
    setAlreadyScheduledConfirmed(true);
    setCurrentModal("");
    setIsDirty(false);

    const { textToSend, imagesUrls } = getDataToSend();

    handleSend(
      textToSend,
      imagesUrls,
      sendOn,
      recurringRule,
      confirmedRestrictedPhrase,
      false,
      useSecondaryNumber,
      confirmedLinkImageWarning,
      true
    );
  }, [
    getDataToSend,
    handleSend,
    sendOn,
    recurringRule,
    confirmedRestrictedPhrase,
    useSecondaryNumber,
    confirmedLinkImageWarning,
  ]);

  const getSendButnText = useMemo(() => {
    if (sendOn && (schedule || editScheduledMessage)) {
      if (sendStatus === "loading") {
        return "Scheduling";
      }
      return `${editScheduledMessage ? "Re-" : ""}Schedule`;
    }
    return sendStatus === "loading" ? "Sending" : "Send";
  }, [editScheduledMessage, schedule, sendOn, sendStatus]);

  const sendMorePopover = useMemo(() => {
    return (
      <Popover
        id="sendMore"
        open={Boolean(sendMoreAnchor)}
        anchorEl={sendMoreAnchor}
        onClose={closeSendMorePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className="p-3">
          {sendOn && schedule ? (
            <div
              className="flexer-row gap-2 cursor-pointer"
              onClick={handleRemoveSchedule}
            >
              <SendGrey height={15} width={15} />
              <span>Send</span>
            </div>
          ) : (
            <div
              className="flexer-row gap-2 cursor-pointer"
              onClick={handleEditSchedule}
            >
              <ScheduledLightGreySvg />
              <span>Schedule</span>
            </div>
          )}
        </div>
      </Popover>
    );
  }, [
    closeSendMorePopOver,
    handleEditSchedule,
    handleRemoveSchedule,
    schedule,
    sendMoreAnchor,
    sendOn,
  ]);

  const buttons = useMemo(() => {
    const isSend =
      !isMessageEmpty &&
      withAssignedNumber &&
      (filteredRecipients?.length !== 0 || mainGroupId > 0);
    const disabled = sendStatus === "loading";
    return [
      {
        text: `Recipients: ${recipientsCount}`,
        onClick: handleToggleRecipients,
      },
      {
        text: (
          <>
            Total Credit: <strong>{Math.ceil(calculatedCredits ?? 0)}</strong>
          </>
        ),
        help: (
          <>
            <CreditEstimate
              credits={calculatedCredits}
              characters={characters}
              smsCount={smsCount}
              mmsCount={mmsCount}
              unicodeCharacters={unicodeCharacters}
              subscribersCount={subscribersCount}
              hideText
              swapForIcon="How is it calculated"
            />
          </>
        ),
      },
      {
        text: getSendButnText,
        className: "send",
        more: true,
        buttonActive: isSend,
        icon: sendButtonIcon,
        disabled,
        onClick: handleSendMessage,
        toolTipDisabled: isSend,
        toolTipTitle: sendButtonToolTipTitle,
        moreToolTipTitle: "Schedule message",
        moreToolTipDisabled: !isSend,
        morePopover: sendMorePopover,
        openMorePopOver: openSendMorePopOver,
      },
    ];
  }, [
    calculatedCredits,
    characters,
    getSendButnText,
    handleSendMessage,
    handleToggleRecipients,
    isMessageEmpty,
    mmsCount,
    openSendMorePopOver,
    recipientsCount,
    sendButtonIcon,
    sendButtonToolTipTitle,
    sendMorePopover,
    sendStatus,
    smsCount,
    unicodeCharacters,
    withAssignedNumber,
    subscribersCount,
    filteredRecipients,
    mainGroupId,
  ]);

  const handleSidebarOutsideClick = useCallback(
    (event) => {
      if (boxRef.current && boxRef.current.contains(event.target) && sidebar) {
        setSidebar(false);
      }
    },
    [sidebar]
  );

  const getThrottleWarningData = useCallback(() => {
    let throttleWarning = false;
    let throttleWarningSecs = null;
    if (
      senderNumber &&
      senderNumber.src !== 5 &&
      senderNumber.src !== 6 &&
      senderNumber.is_high_throughput_number !== true &&
      subscribersCount > 0 &&
      smsThrottles
    ) {
      ({ throttleWarning, throttleWarningSecs } = getSmsThrottleInfoSelector({
        subscribersCount,
        smsThrottles,
      }));
    }

    return { throttleWarning, throttleWarningSecs };
  }, [senderNumber, smsThrottles, subscribersCount]);

  const isNoShorteningLinksWarning = useCallback(
    (text, forceClear = false) => {
      let newLinksCount = linksCount;
      let newShowNoShorteningLinksWarning = showNoShorteningLinksWarning;

      if (forceClear) {
        newLinksCount = 0;
        newShowNoShorteningLinksWarning = false;
      }

      let currentLinksCount = linksCount;

      const regexp =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
      if (text.length > 0) {
        if (!showNoShorteningLinksWarning) {
          const links = text.match(regexp);
          if (links) {
            currentLinksCount = links.length;
          }
        }
      } else {
        currentLinksCount = 0;
      }

      if (!showNoShorteningLinksWarning && currentLinksCount > linksCount) {
        newShowNoShorteningLinksWarning = true;
        clearTimeout(noShorteningLinksWarningTimer.current);
        noShorteningLinksWarningTimer.current = setTimeout(() => {
          const links = text.match(regexp);
          setShowNoShorteningLinksWarning(false);
          setLinksCount(links ? links.length : 0);
        }, 6000);
      }
      newLinksCount = currentLinksCount;

      return {
        linksCount: newLinksCount,
        showNoShorteningLinksWarning: newShowNoShorteningLinksWarning,
      };
    },
    [linksCount, showNoShorteningLinksWarning]
  );

  const alertNotifier = useMemo(() => {
    return (
      <>
        <AlertNotifier
          warningText={warningText}
          imagesErrorText={imagesErrorText}
          videoErrorText={videoErrorText}
          throttleWarning={throttleWarning}
          showSharedNumberAlert={showSharedNumberAlert}
          showNoShorteningLinksWarning={showNoShorteningLinksWarning}
          showSendError={showSendError}
          setCurrentModal={setCurrentModal}
        />
        {/* Message Delivery alert notifier */}
        <MessageDeliveryAlertNotifier threadType={threadType} />
      </>
    );
  }, [
    warningText,
    imagesErrorText,
    videoErrorText,
    throttleWarning,
    showSharedNumberAlert,
    showNoShorteningLinksWarning,
    showSendError,
    threadType,
  ]);

  useEffect(() => {
    // Check if the message contains any links
    if (messageValue) {
      const { linksCount } = isNoShorteningLinksWarning(messageValue);

      setLinksCount(linksCount);
      setShowNoShorteningLinksWarning(false);
    }
  }, [isNoShorteningLinksWarning, messageValue]);

  useEffect(() => {
    // show alert if the user is the main owner and has shares number
    if (isSharesNumber && isMainOwner) {
      setShowSharedNumberAlert(true);
    }
  }, [isMainOwner, isSharesNumber]);

  useEffect(() => {
    // Show throttle warning if the user has more than 1 subscriber
    if (senderNumber !== prevSenderNumber) {
      const { throttleWarning, throttleWarningSecs } = getThrottleWarningData();
      setThrottleWarning({
        show: throttleWarning,
        throttleWarningSecs,
        subscribersCount,
      });
    }
  }, [
    getThrottleWarningData,
    prevSenderNumber,
    senderNumber,
    subscribersCount,
  ]);

  useEffect(() => {
    // Event listener to close the sidebar when user clicks outside the sidebar
    document.addEventListener("mousedown", handleSidebarOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleSidebarOutsideClick);
    };
  }, [handleSidebarOutsideClick]);

  useEffect(() => {
    // Set the signature to the message
    if (numberSignature || signatureToInsert?.signatureId) {
      setMessageSignature(numberSignature);
      toggleSignatureRemoved(false);
    }
  }, [numberSignature, toggleSignatureRemoved, signatureToInsert]);

  useEffect(() => {
    // This useeffect will handle the send message status
    if (sendStatus === "error") {
      if (sendErrorStatus === 95) {
        setShowSendError(true);
        setCurrentModal("confirm-restricted-phrase");
      } else if (sendErrorStatus === 100) {
        setShowSendError(false);
        setCurrentModal("confirm-link-image-warning");
      } else if (sendErrorStatus === 5) {
        setCurrentModal("confirm-already-scheduled");
        setShowSendError(true);
      } else if (sendError) {
        setShowSendError(true);
        setWarningText(sendError);
      }
      clearSendMessage();
    } else if (sendStatus === "success") {
      setIsDirty(false);
      clearSendingData();
      clearSendMessage();
      clearFilteredRecipients();
      clearAddGroup();
      clearGroupIsNewGroup();
      setExistingGroupId(null);
      clearRecipientGroupsForMessage();
      clearManuallyAddedContactIds();
      if (threadType === CONTACT) {
        toggleSendMessageWizard(true);
        markInitialLoadSuccess();
        setFilter("all");
        setMessagesTab(null);
        history.push(`/hub/messages/contact/${interlocutorId}`);
      } else {
        history.push("/broadcast");
      }
    }
  }, [
    clearSendMessage,
    clearSendingData,
    interlocutorId,
    sendError,
    sendErrorStatus,
    sendStatus,
    threadType,
    markInitialLoadSuccess,
    setFilter,
    setMessagesTab,
    clearFilteredRecipients,
    clearAddGroup,
    clearRecipientGroupsForMessage,
    setExistingGroupId,
    clearManuallyAddedContactIds,
    history,
    clearGroupIsNewGroup,
    toggleSendMessageWizard,
  ]);

  useEffect(() => {
    if (existingGroupId !== null) {
      setMainGroupId(existingGroupId);
    } else if (addId) {
      setMainGroupId(addId);
    }
  }, [existingGroupId, addId]);

  useEffect(() => {
    // Clear send message status when the component mount
    if (sendStatus === "success") {
      clearSendMessage();
    }
  }, [clearSendMessage, sendStatus]);

  useEffect(() => {
    // Set trimmed video to videos array
    if (dataForModal?.isVideoTrimmed && !Boolean(videos?.length)) {
      const appendNewVideo = [...videos, dataForModal];
      setVideos(appendNewVideo);
    }
  }, [dataForModal, videos]);

  useEffect(() => {
    // Set vcards to vCards array
    if (
      addVcardsStatus === "success" &&
      vCardsInRedux &&
      Array.isArray(vCardsInRedux) &&
      Boolean(vCardsInRedux?.length)
    ) {
      setVcards((prev) => [...prev, ...vCardsInRedux]);
      clearAddVcardStatus();
    }
  }, [addVcardsStatus, clearAddVcardStatus, vCardsInRedux]);

  const closeVideoLengthExceedModal = useCallback(() => {
    setVideoLengthExceedData({});
  }, []);

  const closeUploadedVideoConfirmationModal = useCallback(() => {
    setUploadedVideo({});
  }, []);

  const onClickedTrimVideo = useCallback(() => {
    addDataForModal(videoLengthExceedData);
    closeVideoLengthExceedModal();
    history.push("#modal-video-trimmer");
  }, [
    addDataForModal,
    closeVideoLengthExceedModal,
    videoLengthExceedData,
    history,
  ]);

  return (
    <>
      <FormPrompt hasUnsavedChanges={isDirty} />
      <LayoutDefault className="inbox send-new-message-layout">
        <>
          <div className="chat-main-row" onClick={onFormClick}>
            <div ref={boxRef} className="chat-main-wrapper">
              <SendNewMessageHeader
                buttons={buttons}
                credits={Math.ceil(calculatedCredits)}
              />
              <SendNewMessageBody
                buttons={buttons}
                messageValue={messageValue}
                setMessageValue={setMessageValue}
                unicodeCharacters={unicodeCharacters}
                credits={calculatedCredits}
                characters={characters}
                smsCount={smsCount}
                mmsCount={mmsCount}
                images={images}
                setImages={setImages}
                videos={videos}
                setVideos={setVideos}
                isVideoProcessing={isVideoProcessing}
                setIsVideoProcessing={setIsVideoProcessing}
                isImagesProcessing={isImagesProcessing}
                setIsImagesProcessing={setIsImagesProcessing}
                messageSignature={messageSignature}
                setMessageSignature={setMessageSignature}
                setSidebar={setSidebar}
                setImagesErrorText={setImagesErrorText}
                setVideoErrorText={setVideoErrorText}
                setVideoLengthExceedData={setVideoLengthExceedData}
                setUploadedVideo={setUploadedVideo}
                recipientsCount={recipientsCount}
                handleEditSchedule={handleEditSchedule}
                alertNotifier={alertNotifier}
                disabled={!withAssignedNumber || sendStatus === "loading"}
                uploadedVideo={uploadedVideo}
                videoLengthExceedData={videoLengthExceedData}
                setWarningText={setWarningText}
                closeVideoLengthExceedModal={closeVideoLengthExceedModal}
                closeUploadedVideoConfirmationModal={
                  closeUploadedVideoConfirmationModal
                }
                onClickedTrimVideo={onClickedTrimVideo}
                subscribersCount={subscribersCount}
                vCards={vCards}
                setVcards={setVcards}
                setMainGroupId={setMainGroupId}
                interlocutorId={interlocutorId}
                handleRemoveSchedule={handleRemoveSchedule}
                groupData={groupData}
                threadType={threadType}
                schedule={schedule}
              />
            </div>
            {sideContent}
          </div>

          <InvalidTimeModal
            show={invalidTimeModal}
            closeModal={handleCloseInvalidModal}
          />
        </>
      </LayoutDefault>

      <MessageFormModalManager
        modal={currentModal}
        modalClose={() => setCurrentModal("")}
        sendOn={sendOn}
        recurringRule={recurringRule}
        shortCodeNumber={shortCodeNumber}
        isBlocked={isBlocked}
        isUnsubscribed={isUnsubscribed}
        contactFullName={contactFullName}
        contactFullNumber={contactFullNumber}
        unsubedByUserId={unsubedByUserId}
        invalidTimeModal={invalidTimeModal}
        handleSendDifferentSender={() => {}}
        handleSendRestricted={handleSendRestricted}
        handleSendLinkImageWarning={handleSendLinkImageWarning}
        handleSwitchToShortcode={handleSwitchToShortcode}
        handleSendAlreadyScheduled={handleSendAlreadyScheduled}
        onCloseInvalidTimeModal={() => setInvalidTimeModal(false)}
        handleScheduleMessage={handleSubmitScheduleMessage}
      />
    </>
  );
};

export default SendNewMessage;
