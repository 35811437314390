import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { default as MuiAvatar } from "@material-ui/core/Avatar";
//new icons
import { ReactComponent as NewMsgSvg } from "../assets/img/icons-new/avatars/new-message/new-message-gray.svg";
import { ReactComponent as MultiContactAvatar } from "../assets/img/icons-new/avatars/multi-app-contact/multi-app-contact-gray.svg";
import { ReactComponent as GroupAvatarIcon } from "../assets/img/icons-new/avatars/group/group-gray.svg";
import { ReactComponent as UnknownContactIcon } from "../assets/img/icons-new/avatars/unknown-contact/unknown-contact-gray.svg";
import { ReactComponent as AllPeopleSvg } from "../assets/img/icons-new/avatars/all-people/all-people-gray.svg";
import { ReactComponent as ImportedHistoryAvatarSvg } from "../assets/img/icons-new/avatars/imported-history-avatar/imported-history-avatar.svg";
import { ReactComponent as AmSvg } from "../assets/img/icons-new/campaign-type/automated-message/automated-message-black.svg";
import { ReactComponent as ContestSvg } from "../assets/img/icons-new/general/contest/contest-purple.svg";
import { ReactComponent as DcSvg } from "../assets/img/icons-new/general/data-capture/data-capture-purple.svg";
import { ReactComponent as PollSvg } from "../assets/img/icons-new/campaign-type/poll/poll-gray.svg";
import { ReactComponent as BirthdaySvg } from "../assets/img/icons-new/campaign-type/birthday/birthday-purple.svg";
import { ReactComponent as TagAvatarIcon } from "../assets/img/icons-new/tags/tag-avatar/tag-avatar-grey.svg";

//missing icons
// import { ReactComponent as AmUserSvg } from "../assets/img/icons/automated_user.svg";
import { ReactComponent as AmUserSvg } from "../assets/img/icons-new/general/dot/dot-red.svg";

import SpanWithTooltip from "./SpanWithTooltip";
const md5 = require("md5");

class Avatar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gravatarChecked: false,
      useGravatar: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (prevProps.email !== this.props.email) {
    //   this.setState({ gravatarChecked: false, useGravatar: true });
    // }
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const showMultiAppAvatar = () => {
      const { filter } = this.props.match.params;
      const multiAppFilterName = "multi-app";
      return (
        filter === multiAppFilterName || filter?.startsWith(multiAppFilterName)
      );
    };

    let letters = "";
    const isMultiAppAvatar =
      this.props.isMultipleContacts || showMultiAppAvatar();

    if (!isMultiAppAvatar) {
      if (this.props.firstName && this.props.lastName) {
        letters =
          this.props.firstName.substr(0, 1) + this.props.lastName.substr(0, 1);
      } else if (this.props.firstName) {
        letters = this.props.firstName.substr(0, 2);
      } else if (this.props.lastName) {
        letters = this.props.lastName.substr(0, 2);
      }
      letters = letters.toUpperCase();
    }

    // For use as logged user avatar in header
    if (this.props.headerMode && !this.props.avatarUrl) {
      return (
        <span className="user-img">
          {(!this.state.gravatarChecked || this.state.useGravatar) &&
            this.props.email && (
              <img
                alt=""
                className={"rounded-circle mr-2"}
                width={38}
                height={38}
                style={{
                  display: this.state.useGravatar ? "" : "none",
                  borderRadius: "50%",
                }}
                src={
                  "https://s.gravatar.com/avatar/" +
                  md5(this.props.email.toLowerCase().trim()) +
                  "?d=404"
                }
                onLoad={(e) => {
                  e.target.onload = null;
                  this.setState({ useGravatar: true });
                }}
              />
            )}
          {!this.state.useGravatar && (
            <span className={`text-avatar mr-2 ${this.props.className}`}>
              {letters}
            </span>
          )}
        </span>
      );
    }

    // For use as contact/group avatar in threads list
    let className =
      "avatar" +
      (this.props.middle ? " middle-avatar" : "") +
      (this.props.big ? " big-avatar" : "") +
      (this.props.bgLight ? " light" : "") +
      (this.props.smallAvatarClass ? " small-avatar" : "") +
      (this.props.selectMenuActive ? " avatar-with-checkbox" : "") +
      (` ${this.props.className}` || "");
    let styles = {};
    if (isMultiAppAvatar) {
      className += " bg-white";
    } else if (this.props.campaignType) {
      className += " bg-white";
    } else if (this.props.isNote) {
      className += " bg-blue";
    } else if (letters === "" && !this.props.isNotification) {
      className += "";
    }

    // if (this.props.bgColor) {
    //   if (this.props.bgLight) {
    //     styles = {backgroundColor: this.props.bgColor + '80'};
    //   } else {
    //     styles = {backgroundColor: this.props.bgColor};
    //   }
    // } else if (this.props.isNotification) {
    //   styles = {backgroundColor: '#9253FF'};
    // }
    styles = { backgroundColor: "#666666", fontFamily: "Roboto" };
    const size = this.props.size ? this.props.size : "100%";

    const content = (
      <React.Fragment>
        {this.props.avatarUrl && (
          <MuiAvatar
            src={this.props.avatarUrl}
            style={{
              width: size,
              height: size,
            }}
          />
        )}
        {!this.props.avatarUrl &&
          !isMultiAppAvatar &&
          !this.props.isImportedHistory &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          (!this.state.gravatarChecked || this.state.useGravatar) &&
          this.props.email && (
            <img
              alt=""
              style={{
                display: this.state.useGravatar ? "" : "none",
                width: "100%",
                objectFit: "contain",
                borderRadius: "50%",
              }}
              src={
                "https://s.gravatar.com/avatar/" +
                md5(this.props.email.toLowerCase().trim()) +
                "?d=404"
              }
              onLoad={(e) => {
                e.target.onload = null;
                this.setState({ useGravatar: true });
              }}
            />
          )}
        {!isMultiAppAvatar &&
          !this.props.isImportedHistory &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          !letters &&
          !!this.props.isGroup && (
            <React.Fragment>
              {!!this.props.isAdhocGroup && (
                <SpanWithTooltip title="New Message">
                  <NewMsgSvg width={size} height={size} />
                </SpanWithTooltip>
              )}
              {!!this.props.isAllPeopleGroup && (
                <SpanWithTooltip title="All People">
                  <AllPeopleSvg
                    width={size}
                    height={size}
                    className={this.props.iconBlack ? "icon-light-black" : ""}
                  />
                </SpanWithTooltip>
              )}
              {!this.props.isAdhocGroup && !this.props.isAllPeopleGroup && (
                <SpanWithTooltip title="All People">
                  <GroupAvatarIcon
                    width={size}
                    height={size}
                    className={
                      this.props.iconBlack
                        ? "icon-light-black"
                        : "unknown-person-avatar"
                    }
                  />
                </SpanWithTooltip>
              )}
            </React.Fragment>
          )}
        {!isMultiAppAvatar &&
          !this.props.isImportedHistory &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          !letters &&
          !this.props.isGroup &&
          !this.props.isGroupTag && (
            <SpanWithTooltip title="Unknown Person">
              <UnknownContactIcon
                width={size}
                height={size}
                className={`${
                  this.props.iconBlack ? "icon-light-black" : ""
                } unknown-person-avatar`}
              />
            </SpanWithTooltip>
          )}
        {!this.props.avatarUrl &&
          !isMultiAppAvatar &&
          !this.props.isImportedHistory &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          letters && (
            <SpanWithTooltip
              title={this.props.firstName + " " + this.props.lastName}
            >
              <span className="text-avatar">{letters}</span>
            </SpanWithTooltip>
          )}
        {this.props.isGroupTag && (
          <SpanWithTooltip title={"Tag"}>
            <TagAvatarIcon
              width={size}
              height={size}
              className={this.props.iconBlack ? "icon-light-black" : ""}
            />
          </SpanWithTooltip>
        )}
        {isMultiAppAvatar && (
          <SpanWithTooltip title="Multiple people, same number">
            <MultiContactAvatar
              width={size}
              height={size}
              className={this.props.iconBlack ? "icon-light-black" : ""}
            />
          </SpanWithTooltip>
        )}
        {this.props.isImportedHistory && (
          <SpanWithTooltip title="Imported history">
            <ImportedHistoryAvatarSvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {this.props.campaignType === "am" && (
          <SpanWithTooltip title={this.props.campaignName || "Campaign Name"}>
            <AmSvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {this.props.campaignType === "contests" && (
          <SpanWithTooltip title={this.props.campaignName}>
            <ContestSvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {this.props.campaignType === "poll" && (
          <SpanWithTooltip title={this.props.campaignName}>
            <PollSvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {this.props.campaignType === "dc" && (
          <SpanWithTooltip title={this.props.campaignName}>
            <DcSvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {this.props.campaignType === "birthday" && (
          <SpanWithTooltip title={this.props.campaignName}>
            <BirthdaySvg width={size} height={size} />
          </SpanWithTooltip>
        )}
        {!this.props.campaignType && this.props.isNote === 1 && (
          <SpanWithTooltip title={this.props.campaignName}>
            <AmUserSvg
              width={size * 0.7}
              height={size * 0.7}
              className={"icon-white"}
            />
          </SpanWithTooltip>
        )}
        {!!this.props.isNotification && (
          <SpanWithTooltip placement="top" title="Notification">
            <i className="material-icons" id="toggleAvatarTooltip">
              notifications
            </i>
          </SpanWithTooltip>
        )}
      </React.Fragment>
    );

    const url = this.props.url ? this.props.url : "#void";
    const doNothing = this.props.url
      ? null
      : (e) => {
          e.preventDefault();
          e.stopPropagation();
        };

    const AvatarTag = this.props.tag ? this.props.tag : "div";

    if (AvatarTag === "a" && url !== "#void") {
      if (url.substr(0, 1) === "#") {
        return (
          <a href={url} className={className} style={styles}>
            {content}
          </a>
        );
      } else {
        return (
          <Link to={url} className={className} style={styles}>
            {content}
          </Link>
        );
      }
    } else {
      return (
        <AvatarTag
          className={className}
          style={styles}
          href={url}
          onClick={this.props.onClick ? this.props.onClick : doNothing}
        >
          {content}
        </AvatarTag>
      );
    }
  }
}

Avatar.propTypes = {
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: "",
};

export default withRouter(Avatar);
