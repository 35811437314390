import React, { useCallback } from "react";
import { getPrimaryUrl } from "../../helpers";
import AxiosConfig from "../../AxiosConfig";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";

const LegacyStartSession = () => {
  // Redux store
  const { startLegacySession, users } = useUsersStore();
  const { showLegacyStartSession } = users;

  const onLoad = useCallback(() => {
    startLegacySession(false);
  }, [startLegacySession]);

  if (!showLegacyStartSession) return null;

  return (
    <iframe
      src={getPrimaryUrl(`users/startSession?token=${AxiosConfig._token}}`)}
      title="start session"
      width={0}
      height={0}
      onLoad={onLoad}
    ></iframe>
  );
};

export default LegacyStartSession;
