import React from "react";
import CustomModal from "./CustomModal";

const CompanyAutoSwitchedModal = (props) => {
  const { company, closeModal } = props;
  return (
    <CustomModal
      {...props}
      show={true}
      className={"locked-info-modal"}
      dialogClassName={"modal-dialog-centered"}
      noHeader
      noFooter
      shakeModal={closeModal}
    >
      <div onClick={closeModal}>
        <p className="font-weight-bold" style={{ color: "white" }}>
          Company has been automatically switched to{" "}
          {company ? company.company_name : "-"}.
        </p>
      </div>
    </CustomModal>
  );
};

export default CompanyAutoSwitchedModal;
