import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import Avatar from "../../../Avatar";
import TooltipWhenOverflow from "../../../hub/HelperComponents/TooltipWhenOverflow";
import RecipientsRemoveButton from "./RecipientsRemoveButton";
import {
  groupName,
  contactName,
  formatPhoneNumber,
  isEmptyObject,
} from "../../../../helpers";
import { ReactComponent as MessageIcon } from "../../../../assets/img/icons-new/main-nav-v2/messages/messages-purple.svg";
import { ReactComponent as ContactIcon } from "../../../../assets/img/icons-new/main-nav-v2/call/call-purple.svg";

import useMessagesStore from "../../../../utils/hooks/ReduxHooks/messagesStore";
import {
  getContactStatusNew,
  getGroupAndContactToBeRemoved,
} from "../../../../utils/sendMessageHelpers";
import NewContactStatusIcons from "../../../NewContactStatusIcons";
import ThreadItemIntegrationIcons from "../../../hub/ThreadItems/ThreadItemIntegrationIcons";
import { isNotAnEmptyArray } from "../../../../utils/settingsHelpers";
import SpanWithTooltip from "../../../SpanWithTooltip";
import useContactsStore from "../../../../utils/hooks/ReduxHooks/contactsStore";

const RecipientsTableViewRow = (props) => {
  const { recipient, selectedViewingFilter } = props;

  const { messages, setRecipientToBeRemoved } = useMessagesStore();
  const { contacts } = useContactsStore();
  const { filteredRecipients, recipientGroups } = messages;
  const { data } = contacts;

  const handleDeleteRecipient = useCallback(() => {
    const contactAndGroupToBeRemoved = getGroupAndContactToBeRemoved(
      recipient,
      filteredRecipients,
      recipientGroups
    );
    setRecipientToBeRemoved(contactAndGroupToBeRemoved);
  }, [filteredRecipients, recipient, setRecipientToBeRemoved, recipientGroups]);

  const isGroup = useMemo(
    () => selectedViewingFilter?.value === "added-groups",
    [selectedViewingFilter]
  );
  const isGroupTag = useMemo(
    () => selectedViewingFilter?.value === "added-tags",
    [selectedViewingFilter]
  );

  const setRecipientName = useMemo(() => {
    if (
      recipient?.hasOwnProperty("group_name") ||
      recipient?.hasOwnProperty("group_type")
    ) {
      return groupName(recipient);
    } else {
      return contactName(
        recipient?.first_name,
        recipient?.last_name,
        recipient?.phone_number
      );
    }
  }, [recipient]);

  const renderRecipientStatusIcon = useMemo(() => {
    if (
      recipient?.hasOwnProperty("group_name") ||
      recipient?.hasOwnProperty("group_type")
    ) {
      return "";
    } else {
      const stats = getContactStatusNew([recipient]);
      return <NewContactStatusIcons withFormatError={true} {...stats} />;
    }
  }, [recipient]);

  const getAddedByText = useCallback(
    (addedByText = "", addedByGroup = [], addedByTagsCount) => {
      if (
        addedByText.startsWith("1") &&
        !isNotAnEmptyArray(addedByGroup) &&
        Boolean(addedByTagsCount)
      ) {
        return "";
      } else if (
        addedByText === "Manually" &&
        isNotAnEmptyArray(addedByGroup)
      ) {
        return `${addedByGroup?.length} Groups`;
      }
      return addedByText;
    },
    []
  );

  const getAddedByColumnInfo = useMemo(() => {
    if (selectedViewingFilter?.value === "all-recipients") {
      return (
        <span className="d-flex flex-column">
          <SpanWithTooltip
            className={clsx({
              "link-text":
                recipient?.added_by_groups &&
                isNotAnEmptyArray(recipient?.added_by_groups),
            })}
            title={
              recipient?.added_by_groups &&
              isNotAnEmptyArray(recipient?.added_by_groups)
                ? recipient?.added_by_groups?.map((group, idx) => (
                    <>
                      <div
                        className="duplicate-recipient-container d-flex align-items-center justify-content-start mr-2"
                        key={idx}
                      >
                        <Avatar
                          isGroup={true}
                          className={"duplicate-recipient-avatar"}
                        />{" "}
                        <span className="duplicate-recipient-label ml-1">
                          {group?.group?.group_name || ""}
                        </span>
                      </div>
                    </>
                  ))
                : ""
            }
          >
            {getAddedByText(
              recipient?.added_by,
              recipient?.added_by_groups,
              recipient?.added_by_tags_count
            )}
          </SpanWithTooltip>

          {Boolean(recipient?.added_by_tags_count) && (
            <SpanWithTooltip
              className={clsx({
                "link-text":
                  recipient?.added_by_tags &&
                  isNotAnEmptyArray(recipient?.added_by_tags),
              })}
              title={
                recipient?.added_by_tags &&
                isNotAnEmptyArray(recipient?.added_by_tags)
                  ? recipient?.added_by_tags?.map((group, idx) => (
                      <>
                        <div
                          className="duplicate-recipient-container d-flex align-items-center justify-content-start mr-2"
                          key={idx}
                        >
                          <Avatar
                            isGroupTag={true}
                            className={"duplicate-recipient-avatar"}
                          />{" "}
                          <span className="duplicate-recipient-label ml-1">
                            {group?.group?.group_name || ""}
                          </span>
                        </div>
                      </>
                    ))
                  : ""
              }
            >
              {recipient?.added_by_tags_count}
            </SpanWithTooltip>
          )}

          {recipient?.added_by === "Manually" &&
            isNotAnEmptyArray(recipient?.added_by_groups) && (
              <span>{recipient?.added_by}</span>
            )}
        </span>
      );
    } else if (selectedViewingFilter?.value === "all-excluded") {
      return recipient?.exclusion_reason || "N/A";
    }
    return `${recipient?.members_count || 0} ${
      recipient?.members_count > 1 ? "Recipients" : "Recipient"
    }`;
  }, [recipient, selectedViewingFilter, getAddedByText]);

  return (
    <tr key={recipient.id} className={"group-table-row"}>
      <>
        <td className="text-nowrap pl-5">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mr-2">
                <Avatar
                  isGroup={isGroup && !isGroupTag}
                  isGroupTag={isGroupTag}
                  firstName={recipient.first_name || ""}
                  lastName={recipient.last_name || ""}
                  email={recipient.email || ""}
                  className={"recipient-avatar"}
                />
              </div>
              <div>
                <span className="d-flex align-items-center">
                  <TooltipWhenOverflow
                    text={setRecipientName}
                    maxWidth={"100%"}
                    className={"mr-1"}
                  >
                    {setRecipientName}
                  </TooltipWhenOverflow>
                  {renderRecipientStatusIcon}
                </span>
                {recipient?.phone_number && (
                  <div className="d-flex align-items-center">
                    <ContactIcon height={13} width={13} />
                    <span className="messages-card__label mb-0">
                      {formatPhoneNumber(
                        recipient?.phone_number,
                        recipient?.country?.phonecode
                      )}
                    </span>
                  </div>
                )}
                {recipient?.email && (
                  <div className="d-flex align-items-center">
                    <MessageIcon height={13} width={13} />
                    <span className="messages-card__label mb-0">
                      {recipient?.email}
                    </span>
                  </div>
                )}
                <span>
                  <span className="d-flex align-items-center">
                    <ThreadItemIntegrationIcons
                      withContactDetails={false}
                      withIntegrationIcon={true}
                      group={isGroup ? recipient : null}
                      isGroup={isGroup}
                      contact={!isGroup ? recipient : null}
                    />
                    {recipient?.app_contact_ids &&
                      isNotAnEmptyArray(recipient?.app_contact_ids) &&
                      recipient?.app_contact_ids?.length > 1 && (
                        <SpanWithTooltip
                          title={recipient?.app_contact_ids?.map(
                            (contactId) => (
                              <>
                                {!isEmptyObject(data) &&
                                  data[contactId] &&
                                  contactId !== recipient?.id && (
                                    <div
                                      className="duplicate-recipient-container d-flex align-items-center justify-content-start"
                                      key={contactId}
                                    >
                                      <Avatar
                                        firstName={
                                          data[contactId]?.first_name || ""
                                        }
                                        lastName={
                                          data[contactId]?.last_name || ""
                                        }
                                        email={data[contactId]?.email || ""}
                                        className={"duplicate-recipient-avatar"}
                                      />{" "}
                                      <span className="duplicate-recipient-label ml-1">
                                        {contactName(
                                          data[contactId]?.first_name,
                                          data[contactId]?.last_name,
                                          data[contactId]?.phone_number
                                        )}
                                      </span>
                                      <span className="ml-1">
                                        <ThreadItemIntegrationIcons
                                          withContactDetails={false}
                                          withIntegrationIcon={true}
                                          group={null}
                                          isGroup={false}
                                          contact={data[contactId]}
                                        />
                                      </span>
                                    </div>
                                  )}
                              </>
                            )
                          )}
                          className="duplicate-profiles ml-1"
                        >
                          + {recipient?.app_contact_ids?.length - 1} Profiles
                        </SpanWithTooltip>
                      )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </td>
      </>

      <td className="text-nowrap">{getAddedByColumnInfo}</td>

      <td className="text-wrap">
        <RecipientsRemoveButton
          onClick={handleDeleteRecipient}
          isAddedByGroup={
            recipient?.added_by?.toLowerCase() !== "manually" &&
            (recipient?.added_by || Boolean(recipient?.added_by_tags_count))
          }
          isExcludedMember={selectedViewingFilter?.value === "all-excluded"}
        />
      </td>
    </tr>
  );
};

export default RecipientsTableViewRow;
